import React from 'react'
import styled from 'styled-components';
import { Link } from 'gatsby'

import Boop from './boop';
 const PrimaryLink = styled(Link)`
    display:inline-block;
    text-decoration: none;
    cursor:pointer;
    background: ${props => props.theme.colors.primary};
    border: none;
    color:  ${props => props.theme.colors.white};
    padding: 1rem 3rem;
    margin: 1rem 0;
    transition:all .35s ease;
    text-align: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    line-height:normal;

    &:hover {
      text-decoration: none;
      background:  ${props => props.theme.colors.gray};
      color:white!important;
    }
`;

const PrimaryButton = styled.button`
display:inline-block;
cursor:pointer;
background: ${props => props.theme.colors.primary};
border: none;
color:  ${props => props.theme.colors.white};
padding: .8rem 1.55rem;
margin: 0;
transition:all .35s ease;
text-align: center;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 8px;

&:hover {
  text-decoration: none;
  background:  ${props => props.theme.colors.primary};
  color:white!important;
}
`;



const PrimaryExternalLink = styled.a`
display:inline-block;
text-decoration:none;
border-radius:8px;
cursor:pointer;
background:  ${props => props.theme.colors.primary};
border: none;
color:  ${props => props.theme.colors.white};
padding: 0.5rem 1.8rem;
margin: 0;
/*margin-bottom:.5rem;*/
transition:all .35s ease;
text-align: center;
&:hover {
  text-decoration: none;
  background:  ${props => props.theme.colors.gray};
  color:white!important;

}
`;


const BtnPrimary = ({children,  ...props}) => {
 
return (
    <Boop scale={1.02} timing={100} >
      
      {props.external ? 
      <PrimaryExternalLink target="_blank" rel="nofollow noopener noreferrer" href={props.to} {...props}>{children}</PrimaryExternalLink> 
      :  ( props.button ?
      <PrimaryButton {...props}>{children}</PrimaryButton> : <PrimaryLink {...props}>{children}</PrimaryLink>
      
      )
    }
    </Boop>
     
)
 }
export default BtnPrimary;