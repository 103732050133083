import React from "react"
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import styled from 'styled-components'
import Layout from "../components/layout"
import Button from "../components/button"
import { Fade } from "react-reveal" 
import YoutubeVideo from "../components/youtubeVideo"
import FormatDate  from '../components/formatDate'
import { HelmetDatoCms } from 'gatsby-source-datocms'

const FlexContent = styled.div`
 	  display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items:flex-start;
   
    flex-basis:33%;
    @media (max-width: ${props => props.theme.breakpoints[0]}) {
      flex-direction:column;
      gap:1rem;
     
      } 
`

const Flex = styled.div`
  position:relative;    
  margin:0 auto;
  max-width:1150px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction:row;
  padding-bottom: 10rem;
  gap:5rem;
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    flex-direction:column-reverse;
    gap:2rem;
    padding-bottom:4rem;
  } 
  h1 {
    font-size:5.7rem;
  }
`

const IllustrationDisque = styled.div`   
    margin-top:1rem;
    width:100%;
    @media (min-width: ${props => props.theme.breakpoints[0]}) {
    max-width:550px;
    margin-top:-5rem;
    position:sticky;
    top:2rem;
    
} 
.imageDisque {box-shadow: 0 8px 15px 0 ${props => props.theme.colors.gray};}
`

const ContentDisque = styled.div`   
    width:100%;
    @media (min-width: ${props => props.theme.breakpoints[0]}) {
    max-width:727px;
} `


const ButtonWrapper = styled.div`   
    margin-bottom:8rem;
    display:flex;
    gap:1.5rem;
    justify-content:center;
    flex-wrap:wrap;
    @media (min-width: ${props => props.theme.breakpoints[0]}) {
    } 
`

const Pro = ({data}) => {
  
  return (
  <Layout>
    <HelmetDatoCms >
        <meta name="robots" content="noindex, nofollow"></meta>
    </HelmetDatoCms>
    <h1 className="breadcrumb">Pros</h1>
    <div className="container">
        <Flex>   
            <ContentDisque>
                <span className="date-small gray"><FormatDate date={data.disque.date}/></span>
                <Fade up duration={700} distance="70px"><h1>{data.disque.titre}</h1></Fade>
                <div dangerouslySetInnerHTML={{__html: data.disque.detail}} />
            </ContentDisque> 
            <IllustrationDisque> 
              <Fade down duration={700} distance="70px">
                <GatsbyImage alt={data.disque.titre} image={data.disque.image.gatsbyImageData} className="imageDisque"/>
              </Fade>
            </IllustrationDisque> 
        </Flex>
        <ButtonWrapper>
        <Button external to = {data.pro.soundcloud}>Ecouter l'album</Button>
        <Button external to = {data.pro.dossier.url}>Télecharger le dossier de présentation</Button>
        <Button external to = {data.pro.bio.url}>Télecharger la biographie</Button>
        </ButtonWrapper>
    </div>
    <section className="full-green" style={{'marginBottom': '5rem'}}>
    <div className="container page-pro" >
      <FlexContent>
      { data.pro.videos.map(( item,i) => ( 
          <YoutubeVideo videoSrcURL={`https://www.youtube.com/embed/`+item.video.providerUid} videoTitle={item.titre} key={i} />    
      ))
        }
      </FlexContent>
    </div>
    </section>
    <div className="container maxwidth-800">
    <div dangerouslySetInnerHTML={{__html: data.pro.content}}/>

    </div>
   
  </Layout>
  )
}

export default Pro


export const query = graphql`
  query ProQuery {
    pro:datoCmsPagePro {
    content
    videos {
        video {
            providerUid
            thumbnailUrl
            url
         }
    }
    bio {url}
    dossier {url}
    soundcloud
  }

  disque:datoCmsDisque( slug: {eq: "apnee" }) {
    id
    titre
    date
    detail
    slug
    lienPhysique
    lienNumerique
    image {
      gatsbyImageData (
        placeholder: BLURRED
      )
      alt
    }
}
}
`