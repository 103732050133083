import React from "react"
const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className="videoItem">
    <div className="videoWrapper">  
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
    </div>
    <p>{videoTitle}</p>
  </div>
)
export default Video